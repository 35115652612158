import '@fortawesome/fontawesome-free/css/all.min.css'
import '@factry/bulma/scss/bulma.scss'
import * as Sentry from '@factry/sentry'
import { initialize } from '@factry/base'

import App from './App.svelte'
import background from '@factry/user-manager/src/assets/background-5.jpg'
import logo from './assets/surahammars.png'

initialize({
  availableLocales: ['sv-SE', 'en'],
  background,
  basePath: import.meta.env.VITE_BASE_PATH,
  defaultLocale: 'sv-SE',
  logo,
  logoAlt: 'Surahammars',
  test: import.meta.env.VITE_TEST,
  versions: import.meta.env.VITE_VERSIONS,
  organization: 'surahammars',
})

// Sentry.init({
//   version: import.meta.env.VITE_VERSION,
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   environment: import.meta.env.MODE,
// })

const app = new App({
  target: document.body,
})

export default app
